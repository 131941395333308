import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CacheService } from '../../_services/cache.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CloudFlareService } from '../../_services/cloudflare.service';
import { Subject, takeUntil } from 'rxjs';
import { IUSER } from '../../_interfaces/user.interface';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})

export class ProfileComponent implements OnInit {

  unSubscribe = new Subject<void>();
  userData!: IUSER;
  recentData: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private cfs: CloudFlareService,
    public cc: CacheService
  ) {

  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.getPublicUser(id || '');
  }

  getPublicUser(id: string) {
    this.cfs.getKvData(id, 'user').pipe(takeUntil(this.unSubscribe)).subscribe(response => {
      if (response.data) {
        this.userData = response.data;
        this.cc.user = this.userData;
        this.getRecentData();
      } else {
        throw new Error(`Failed to get value for id ${id}`);
      }
    })
  }

  getRecentData() {
    this.recentData = [];
    const recentNotes = this.userData.notes;
    const recentLists = this.userData.lists;

    // Merge and add 'type' field
    const mergedData = [
      ...recentNotes.map(note => ({
        ...note,
        type: 'Note',
        updatedAt: this.convertFirestoreTimestampToDate(note.updatedAt)
      })),
      ...recentLists.map(list => ({
        ...list,
        type: 'List',
        updatedAt: this.convertFirestoreTimestampToDate(list.updatedAt)
      }))
    ];

    // Sort by timestamps and take the top 10
    this.recentData = mergedData
      .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
      .slice(0, 10);
  }

  socialToIconMapper(url: string) {
    if (url.includes('facebook')) {
      return 'facebook.svg';
    } else if (url.includes('twitter') || url.includes('x.com')) {
      return 'x.svg';
    } else if (url.includes('instagram')) {
      return 'instagram.svg';
    } else if (url.includes('behance')) {
      return 'behance.svg';
    } else if (url.includes('linkedin')) {
      return 'linkedin.svg';
    } else if (url.includes('youtube')) {
      return 'youtube.svg';
    } else if (url.includes('github')) {
      return 'github.svg';
    } else if (url.includes('dribbble')) {
      return 'dribbble.svg';
    } else if (url.includes('discord')) {
      return 'discord.svg';
    } else if (url.includes('medium')) {
      return 'medium.svg';
    } else if (url.includes('pinterest')) {
      return 'pinterest.svg';
    } else if (url.includes('snapchat')) {
      return 'snapchat.svg';
    } else {
      return this.getFirstLetterFromDomain(url);
    }
  }

  getFirstLetterFromDomain(url: string) {
    try {
      // Parse the URL
      const domain = new URL(url).hostname;
      
      // Remove "www." if it exists
      const cleanDomain = domain.startsWith('www.') ? domain.slice(4) : domain;
      
      // Return the first letter
      return cleanDomain.charAt(0).toUpperCase(); // Convert to uppercase if needed
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  }
  
  // Convert Firestore timestamp to JavaScript Date
  convertFirestoreTimestampToDate(timestamp: { _seconds: number; _nanoseconds: number }): Date {
    return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }

}
