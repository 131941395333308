<div class="ri-bg-dark-4 main-block ri-device-container">
    <div class="header-section ri-bg-secondary">
        <div class="d-flex justify-content-between">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">{{ listData ? listData.title : '-' }}</h5>
            <div class="d-flex align-items-center">
                <img class="img img-fluid ri-me-22px" type="button" (click)="openShareBottomSheet($event)"
                    src="assets/icons/peoples.svg">
                <!-- <img class="img img-fluid filter-icon" type="button" src="assets/icons/filter-icon.svg" (click)="toggleFilter(isFilter)"> -->
                <svg width="24" type="button" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleFilter(isFilter)">
                    <path d="M17.7975 6.84498V6.9784L12.6439 12.7046V17.5897L11.4986 17.0171V12.7046L6.34498 6.9784V6.84498H17.7975ZM18.9427 5.69973H5.19973V7.4176L10.3534 13.1439V17.7249L13.7891 19.4427V13.1439L18.9427 7.4176V5.69973Z" fill="#FFFFFD"/>
                    <path [attr.fill-rule]="isFilter ? null : 'evenodd'" clip-rule="evenodd" d="M12.6439 12.7046L17.7975 6.9784V6.84498H6.34498V6.9784L11.4986 12.7046V17.0171L12.6439 17.5897V12.7046ZM12.5457 17.4309V12.667L17.6971 6.94314H6.44531L11.5968 12.667V16.9564L12.5457 17.4309ZM19.0409 5.60156V7.45527L13.8873 13.1815V19.6016L10.2552 17.7855V13.1815L5.10156 7.45527V5.60156H19.0409ZM10.3534 13.1439V17.7249L13.7891 19.4427V13.1439L18.9427 7.4176V5.69973H5.19973V7.4176L10.3534 13.1439Z" fill="#FFFFFD"/>
                    </svg>
                    
            </div>
        </div>
    </div>
    <div class="body-section">
        <div class="filter-block ri-px-15 d-flex ri-bg-dark-1 ri-bb-2px" [ngClass]="{'h-auto filtered': isFilter}">
            <div class="btn btn-outline input-btn d-flex align-items-center me-2" (click)="filterType = 'SEARCH'" [ngClass]="{'active': filterType === 'SEARCH'}">
                <img class="img img-fluid pe-2" type="button" src="assets/icons/search.svg">
                <input class="search-input w-100 p-0" type="text" placeholder="Search" name="riListSearch" [formControl]="listSearch" autocomplete="off">
            </div>
            <button class="btn btn-outline btn-toggle me-2" (click)="filterType = (filterType === 'UNCHECKED' ? '' : 'UNCHECKED');listSearch.setValue('');onFilterChange()" [ngClass]="{'active': filterType === 'UNCHECKED'}">Unchecked</button>
            <button class="btn btn-outline btn-toggle me-2" (click)="filterType = (filterType === 'CHECKED' ? '' : 'CHECKED');listSearch.setValue('');onFilterChange()" [ngClass]="{'active': filterType === 'CHECKED'}">Checked</button>
        </div>
        <div class="list-block ri-bg-dark-1" *ngFor="let item of listItems; let i = index;">
            <div class="d-flex justify-content-between">
                <p class="mb-0 ri-white-2 ri-fs-16 fw-normal">{{ item.item }}</p>
                <div class="custom-checkbox ms-3">
                    <input type="checkbox" [id]="'listCheck'+i" [checked]="item.done">
                    <label [for]="'listCheck'+i"></label>
                </div>
            </div>
        </div>
        <div class="no-result-block ri-bg-dark-1 d-flex align-items-center justify-content-center" [ngClass]="{'filter-applied': isFilter}" *ngIf="listItems.length === 0">
            <img class="img img-fluid" src="assets/icons/no-result.svg">
        </div>
    </div>
    <div class="footer-section ri-bg-dark-1">
        <div class="text-end">
            <a class="d-bold ri-primary ri-lh-20 ri-fw-500 ri-fs-16 ri-td-none">SAVE LIST</a>
        </div>
    </div>
</div>