<div class="ri-bg-dark-4 main-block ri-device-container">
    <div class="header-section ri-bg-secondary">
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">{{ noteData ? noteData.title : '-' }} <span
                    class="ri-fs-12 ps-1" *ngIf="noteData">{{ noteData.updatedAt | date:'d MMM yyyy, E' }}</span></h5>
                    <img class="img img-fluid" type="button" (click)="openShareBottomSheet($event)" src="assets/icons/peoples.svg">
        </div>
    </div>
    <div class="body-section ri-bg-dark-1">
        <div class="note-block ri-white ri-fs-16" *ngIf="noteData">
            <!-- '"object" | "json" | "html" | "text"' -->
            <quill-editor class="w-100" [modules]="quillModules" [readOnly]="true" [format]="'json'" [(ngModel)]="noteData.content"></quill-editor>
        </div>
        <div class="note-block ri-white" *ngIf="!noteData">-</div>
        <div class="emoji-block d-flex justify-content-between mx-auto">
            <div class="emoji" [ngClass]="{ 'active-emoji' : noteData && i === noteData.emotion }"
                *ngFor="let emoji of emojis; let i = index;" [innerHTML]="getSanitizedSvg(emoji)"></div>
        </div>
    </div>
    <div class="footer-section ri-bg-dark-1">
        <div class="text-end">
            <a class="d-bold ri-primary ri-lh-20 ri-fw-500 ri-fs-16 ri-td-none">SAVE NOTE</a>
        </div>
    </div>
</div>