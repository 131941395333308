// export const CF_ACCOUNT_ID = "82452fcb6c384ef7f3e458ed4663d3a8";
// export const CF_USER_NAMESPACE_ID = "4c1ab5d8308d41b68ac12461ee225576";
// export const CF_NOTE_NAMESPACE_ID = "aea3f9e097e84798a540644ce89d0402";
// export const CF_LIST_NAMESPACE_ID = "7399d9432af54503b660c3424bb0d210";
// export const CF_API_TOKEN = "Kz3q_KD4Z4g5ImG-lapdjcFclFSNpqP2UCtnZyyC";
// export const CF_API_BASE = `https://api.cloudflare.com/client/v4/accounts/${CF_ACCOUNT_ID}/storage/kv/namespaces`;
// export const CF_API_USER_NAMESPACE = `${CF_API_BASE}/${CF_USER_NAMESPACE_ID}`;
// export const CF_API_NOTE_NAMESPACE = `${CF_API_BASE}/${CF_NOTE_NAMESPACE_ID}`;
// export const CF_API_LIST_NAMESPACE = `${CF_API_BASE}/${CF_LIST_NAMESPACE_ID}`;

export const environment = {
    env: 'dev',
    cfApiBase: "https://mevolve.aniket-singh.workers.dev",
};
