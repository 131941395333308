import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CacheService } from '../../_services/cache.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router, RouterLink } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import QRCodeStyling from 'qr-code-styling';
import Konva from 'konva';

@Component({
  selector: 'app-qr-code-bottomsheet',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
  templateUrl: './qr-code-bottomsheet.component.html',
  styleUrl: './qr-code-bottomsheet.component.scss'
})

export class QrCodeBottomsheetComponent {

  @ViewChild('qrCanvas', { static: true }) qrCanvas!: ElementRef;
  link: string = window.location.href;
  qrCode: QRCodeStyling | null = null;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<QrCodeBottomsheetComponent>,
    public cc: CacheService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { title: string, type: 'List' | 'Note' },
    private clipboard: Clipboard,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      type: 'svg',
      data: this.link,
      image: '/assets/icons/me.svg',
      margin: 1.5,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'H'
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.6,
        margin: 6,
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: '#FFFFFD',
        type: 'extra-rounded'
      },
      backgroundOptions: {
        color: '#22252D',
      },
      cornersSquareOptions: {
        color: '#FFFFFD',
        type: 'square'
      },
      cornersDotOptions: {
        color: '#FFFFFD',
        type: 'square'
      }
    });

    this.qrCode.append(this.qrCanvas.nativeElement);
  }

  createImageWithKonva() {
    // Generate the QR code image
    this.qrCode?.getRawData('png').then((imageBlob: Blob | any) => {
      const url = URL.createObjectURL(imageBlob as Blob);
      // Create the Konva stage
      const stage = new Konva.Stage({
        container: 'konva-container',
        width: 570,
        height: 370,
      });

      const PADDING = 32; // Padding for the background
      const BORDER_RADIUS = 16; // Border radius for rounded corners

      // Layer with clipping for rounded corners
      const layer = new Konva.Layer({
        clipFunc(ctx) {
          ctx.beginPath();
          ctx.moveTo(BORDER_RADIUS, 0);
          ctx.arcTo(stage.width(), 0, stage.width(), stage.height(), BORDER_RADIUS);
          ctx.arcTo(stage.width(), stage.height(), 0, stage.height(), BORDER_RADIUS);
          ctx.arcTo(0, stage.height(), 0, 0, BORDER_RADIUS);
          ctx.arcTo(0, 0, stage.width(), 0, BORDER_RADIUS);
          ctx.closePath();
        },
      });
      stage.add(layer);

      // Add a background rectangle with padding and rounded corners
      const background = new Konva.Rect({
        x: -PADDING,
        y: -PADDING,
        width: stage.width() + 2 * PADDING,
        height: stage.height() + 2 * PADDING,
        fill: '#1A1C23',
        cornerRadius: 16,
      });
      layer.add(background);

      // Add the Mevolve image
      const imageObj = new Image();
      imageObj.src = '/assets/imgs/mevolve.svg';
      imageObj.onload = () => {
        const konvaImage = new Konva.Image({
          x: 32, // Center horizontally
          y: 32, // Position near the top
          image: imageObj,
          width: 100,
          height: 35.33,
        });
        layer.add(konvaImage);

        // Add the QR code
        const qrImageObj = new Image();
        qrImageObj.src = url; // Use the QR code's data URL
        qrImageObj.onload = () => {
          const qrCodeImage = new Konva.Image({
            x: stage.width() / 2 - 75, // Center horizontally
            y: 100,
            image: qrImageObj,
            width: 150, // Match QR code dimensions
            height: 150,
          });
          layer.add(qrCodeImage);

          // Add the path text
          const path = new Konva.Text({
            x: 32,
            y: 290, // Adjust position to ensure it's visible
            width: stage.width() - 64, // Account for padding
            text: `${window.location.host}${window.location.pathname}`,
            fontSize: 14,
            fontFamily: 'Roboto',
            fill: '#FFFFFF',
            lineHeight: 1.2,
            align: 'left',
          });
          layer.add(path);

          // Add the descriptive text
          const text = new Konva.Text({
            x: 32,
            y: 320, // Adjust position below the path text
            width: stage.width() - 64, // Account for padding
            text: `Start visiting ${this.data.type} via QR or Link`,
            fontSize: 14,
            fontFamily: 'Roboto',
            fill: '#FFFFFF',
            lineHeight: 1.2,
            align: 'left',
          });
          layer.add(text);

          // Render the layer and export the image
          layer.draw();
          const link = document.createElement('a');
          link.href = stage.toDataURL();
          link.download = 'final-image.png';
          link.click();
        };
      };
    });
  }

  viewUser() {
    this.close();
    this.router.navigate(['/user', this.cc.user.uid]);
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  copyLink() {
    this.clipboard.copy(window.location.href);
  }
}
