import { Component, OnInit } from '@angular/core';
import { CloudFlareService } from '../../_services/cloudflare.service';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ILIST, ILIST_ITEM } from '../../_interfaces/list.interface';
import { CacheService } from '../../_services/cache.service';
import { CommonModule } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ShareBottomsheetComponent } from '../../shared/share-bottomsheet/share-bottomsheet.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-list-sharing',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './list-sharing.component.html',
  styleUrl: './list-sharing.component.scss'
})

export class ListSharingComponent implements OnInit {

  unSubscribe = new Subject<void>();
  listData!: ILIST;
  listSearch: FormControl = new FormControl("");
  isFilter: boolean = false;
  filterType: 'SEARCH' | 'CHECKED' | 'UNCHECKED' | '' = '';
  listItems: ILIST_ITEM[] = [];

  constructor(
    private route: ActivatedRoute,
    private cfs: CloudFlareService,
    public cc: CacheService,
    private bottomSheet: MatBottomSheet
  ) {

    this.listSearch.valueChanges.pipe(
      debounceTime(300), // Wait for 300ms after the user stops typing
      distinctUntilChanged(),// Only trigger if the input value has actually changed
      takeUntil(this.unSubscribe)
    ).subscribe((searchVal: string) => {
      this.onFilterChange(searchVal);
    });

  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.getPublicList(id || '');
  }

  toggleFilter(isFilter: boolean) {
    this.isFilter = !isFilter;
    this.filterType = '';
    this.listSearch.setValue('');
    this.onFilterChange();
  }

  onFilterChange(searchValue?: string) {
    const searchVal = searchValue?.toLowerCase() || '';

    let filteredLists = this.listData.items;
    // Single-pass filter application
    filteredLists = filteredLists.filter(list => {
      // Search filter
      if (searchVal) {
        const fieldsToSearch = [
          list.item,
          list.description,
        ];
        const matchesSearch = fieldsToSearch.some(field => field?.toLowerCase().includes(searchVal));
        if (!matchesSearch) return false;
      }

      if (this.filterType === 'CHECKED' && !list.done) {
        return false;
      }

      if (this.filterType === 'UNCHECKED' && list.done) {
        return false;
      }

      return true;
    });
    this.listItems = filteredLists;
  }

  getPublicList(id: string) {
    this.cfs.getKvData(id, 'list').pipe(takeUntil(this.unSubscribe)).subscribe(response => {
      if (response.data) {
        this.listData = response.data;
        this.onFilterChange();
        this.cc.getPublicUser(this.listData.userId);
      } else {
        throw new Error(`Failed to get value for id ${id}`);
      }
    })
  }

  openShareBottomSheet(e: any) {
    this.bottomSheet.open(ShareBottomsheetComponent, {
      data: {
        title: 'List Share',
        qrTitle: this.listData.title,
        type: 'List'
      }
    });

    e.stopPropagation();
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
    this.cc.stopSubscription();
  }

}
