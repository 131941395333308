import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class CloudFlareService {

    private apiBase = environment.cfApiBase;

    constructor(private http: HttpClient) {

    }

    getKvData(id: string, kvType: 'list' | 'note' | 'user'): Observable<any> {
        return this.http.get(`${this.apiBase}/${kvType}/${id}`);
    }
}
