import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router, RouterLink } from '@angular/router';
import { CacheService } from '../../_services/cache.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { QrCodeBottomsheetComponent } from '../qr-code-bottomsheet/qr-code-bottomsheet.component';

@Component({
  selector: 'app-share-bottomsheet',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,

  ],
  templateUrl: './share-bottomsheet.component.html',
  styleUrl: './share-bottomsheet.component.scss'
})
export class ShareBottomsheetComponent {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ShareBottomsheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { title: string, qrTitle: string, type: 'List' | 'Note' },
    public cc: CacheService,
    private clipboard: Clipboard,
    private bottomSheet: MatBottomSheet,
    private router: Router,
  ) {

  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  copyLink() {
    this.clipboard.copy(window.location.href);
  }

  viewUser() {
    this.close();
    this.router.navigate(['/user', this.cc.user.uid]);
  }

  viewQrCode(e: any) {
    this.close();
    this.bottomSheet.open(QrCodeBottomsheetComponent, {
      data: {
        title: this.data.qrTitle,
        type: this.data.type
      }
    });

    e.stopPropagation();
  }
}
