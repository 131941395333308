<div class="ri-bg-dark-1">
    <div class="bs-header ri-bg-secondary">
        <div class="d-flex justify-content-between">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">Share QR & Link</h5>
            <div class="d-flex align-items-center">
                <div class="user-badge ri-me-22px" *ngIf="cc.user && cc.user.uid" role="button" [routerLink]="['/user', cc.user.uid]"><span class="user-initial ri-fw-500">{{ cc.getUserDisplayLetter() }}</span></div>
                <img class="img img-fluid" type="button" src="assets/icons/close.svg" (click)="close()">
            </div>
        </div>
    </div>
    <div class="bs-body pb-0">
        <div class="ri-p-15">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20">{{ data.title }}</p>
            <div #qrCanvas id="qrCodeCanvas" class="text-center mt-4"></div>
        </div>
        <div class="ri-p-15 d-flex justify-content-between ri-bt-1px">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-400 ri-lh-20 mb-0">{{ link }}</p>
            <img class="img img-fluid" type="button" src="assets/icons/copy.svg" (click)="copyLink()">
        </div>
        <div class="ri-p-15 d-flex justify-content-between ri-bt-1px">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-400 ri-lh-20 mb-0">Download</p>
            <img class="img img-fluid" type="button" (click)="createImageWithKonva()" src="assets/icons/download.svg">
        </div>
    </div>
</div>

<div id="konva-container" class="d-none" style="background-color: #231a1a; border-radius: 16px; padding: 32px;"></div>