<div class="ri-bg-dark-4 main-block">
    <div class="header-section ri-bg-secondary">
        <div class="ri-device-container d-flex justify-content-between align-items-center ri-px-15">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">Profile</h5>
        </div>
    </div>
    <div class="body-section ri-device-container">
        <div class="py-4 ri-px-15">
            <div class="d-flex justify-content-between">
                <div class="profile-icon">{{ cc.getUserDisplayLetter() }}</div>
                <div class="d-flex">
                    <div class="text-center ri-me-22px">
                        <h5 class="ri-fs-16 ri-fw-500 mb-2 ri-lh-20 ri-secondary-2">{{ userData ?
                            userData.followersCount : 0 }}</h5>
                        <p class="ri-fs-12 ri-fw-400 ri-lh-14 ri-secondary-2 mb-0">Followers</p>
                    </div>
                    <div class="text-center">
                        <h5 class="ri-fs-16 ri-fw-500 mb-2 ri-lh-20 ri-secondary-2">{{ userData ?
                            userData.followingCount : 0 }}</h5>
                        <p class="ri-fs-12 ri-fw-400 ri-lh-14 ri-secondary-2 mb-0">Following</p>
                    </div>
                </div>
            </div>

            <div class="ri-pt-15 d-flex justify-content-between">
                <div class="">
                    <p class="ri-white ri-fs-18 ri-fw-500 mb-0 ri-lh-24">{{ userData ? userData.name : '-' }}</p>
                    <p class="ri-white ri-fs-14 ri-fw-400 mb-0 ri-lh-20 ri-pt-15">&#64;{{userData ? userData.mevolveId : '-'}}
                    </p>
                </div>
                <button class="btn btn-unfollow ri-secondary-2 ri-fs-14 ri-fw-400 ri-lh-20">Follow</button>
            </div>

            <p class="ri-white ri-fs-14 ri-fw-400 mb-0 ri-lh-20 ri-pt-15" *ngIf="userData && userData.bio">{{ userData.bio }}</p>

            <p class="ri-primary ri-fs-14 ri-fw-400 mb-0 ri-lh-20 ri-pt-15" *ngIf="userData && userData.profileTags.length">
                <span class="pe-2" *ngFor="let tag of userData.profileTags">#{{tag}}</span>
            </p>

            <div class="social-media" *ngIf="userData && userData.socialLinks.length">
                <a class="social-icon ri-pt-15" *ngFor="let url of userData.socialLinks" [href]="url" target="_blank">
                    <img class="img img-fluid" [src]="'assets/social/'+ socialToIconMapper(url)" *ngIf="socialToIconMapper(url) && socialToIconMapper(url)?.includes('.svg'); else otherSocialMediaIcon">
                    <ng-template #otherSocialMediaIcon>
                        <div class="other-social" *ngIf="socialToIconMapper(url)">{{ socialToIconMapper(url) }}</div>
                    </ng-template>
                </a>
            </div>

            <div class="ri-pt-15">
                <div class="d-flex g-0">
                    <div class="text-center ri-bg-dark-1 counts-block left w-100">
                        <h5 class="ri-fs-16 ri-fw-500 mb-2 ri-lh-20 ri-white">{{ userData && userData.lists ? userData.lists.length : 0 }}</h5>
                        <p class="ri-fs-12 ri-fw-400 ri-lh-14 ri-white mb-0">Lists</p>
                    </div>
                    <div class="text-center ri-bg-dark-1 counts-block right w-100">
                        <h5 class="ri-fs-16 ri-fw-500 mb-2 ri-lh-20 ri-white">{{ userData && userData.notes ? userData.notes.length : 0 }}</h5>
                        <p class="ri-fs-12 ri-fw-400 ri-lh-14 ri-white mb-0">Notes</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="recent-block ri-bg-dark-1">
            <h5 class="ri-fs-18 ri-fw-700 ri-lh-24 mb-0 ri-px-15 ri-white ri-bb-2px py-2">Most Recent</h5>
            <div class="recent-list ri-px-15 ri-bb-1px ri-pt-15 pb-3" *ngFor="let data of recentData;" role="button" [routerLink]="[data.type === 'List' ? '/list' : '/note', data.id]">
                <p class="ri-fs-16 ri-fw-500 ri-lh-20 mb-0 ri-white">{{ data.title }}</p>
                <div class="d-flex ri-pt-15">
                    <p class="ri-pe-15 ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14"><img class="img img-fluid pe-1" src="assets/icons/note.svg">{{ data.type }}</p>
                    <p class="ri-pe-15 ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14"><img class="img img-fluid pe-1" src="assets/icons/calendar.svg">{{ data.updatedAt | date:'d MMM y'}}</p>
                    <p class="ri-pe-15 ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14">
                        <img class="img img-fluid pe-1" [src]="'assets/icons/' + (data.type === 'List' ? 'circle-check.svg' : 'attachment.svg')">
                        <span *ngIf="data.type === 'List'">{{ data.completedCount }}/{{ data.itemCount }}</span>
                        <span *ngIf="data.type === 'Note'">{{ data.attachmentCount && data.attachmentCount > 10 ? '10+' : data.attachmentCount }}</span>
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="ri-device-container text-center py-4">
            <img class="img img-fluid mb-4" src="assets/imgs/user-placeholder.svg">
            <p class="ri-fs-16 ri-white ri-lh-24">{{ userData ? userData.name : '-' }}</p>
            <p class="ri-fs-18 ri-secondary-2 ri-lh-20 mb-0">&#64;{{userData ? userData.mevolveId : '-'}}</p>
        </div>
        <div class="info-block ri-bg-dark-1">
            <div class="ri-device-container d-flex justify-content-between">
                <p class="mb-0 ri-white-2 ri-fs-16 fw-normal">Saved Lists</p>
                <p class="mb-0 ms-3 ri-fs-16 ri-primary ri-lh-20 fw-normal">{{ userData && userData.lists ? userData.lists.length : 0 }}</p>
            </div>
        </div>
        <div class="info-block ri-bg-dark-1">
            <div class="ri-device-container d-flex justify-content-between">
                <p class="mb-0 ri-white-2 ri-fs-16 fw-normal">Saved Notes</p>
                <p class="mb-0 ms-3 ri-fs-16 ri-primary ri-lh-20 fw-normal">{{ userData && userData.notes ? userData.notes.length : 0 }}</p>
            </div>
        </div> -->
    </div>
</div>