import { Injectable } from '@angular/core';
import { CloudFlareService } from './cloudflare.service';
import { IUSER } from '../_interfaces/user.interface';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class CacheService {

    user!: IUSER;
    unSubscribe = new Subject<void>();

    constructor(private cfs: CloudFlareService) {

    }

    getPublicUser(id: string) {
        this.cfs.getKvData(id, 'user').subscribe(response => {
            if (response.data) {
                this.user = response.data;
            } else {
                throw new Error(`Failed to get value for id ${id}`);
            }
        })
    }

    getUserDisplayLetter(): string {
        return this.user ? this.user.name.charAt(0) : '-';
    }

    stopSubscription() {
        this.unSubscribe?.next();
        this.unSubscribe?.complete();
    }
}
