<div class="ri-bg-dark-1">
    <div class="bs-header ri-bg-secondary">
        <div class="d-flex justify-content-between">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">{{ data.title }}</h5>
            <div class="d-flex align-items-center">
                <div class="user-badge ri-me-22px" *ngIf="cc.user && cc.user.uid" role="button" (click)="viewUser()"><span class="user-initial ri-fw-500">{{ cc.getUserDisplayLetter() }}</span></div>
                <img class="img img-fluid" type="button" src="assets/icons/close.svg" (click)="close()">
            </div>
        </div>
    </div>
    <div class="bs-body">
        <div class="d-flex justify-content-between">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">Shareable Link</h5>
            <div class="d-flex align-items-center">
                <img class="img img-fluid ri-me-22px" (click)="copyLink()" type="button" src="assets/icons/copy.svg">
                <img class="img img-fluid" type="button" (click)="viewQrCode($event)" src="assets/icons/qr-code.svg">
            </div>
        </div>
    </div>
</div>