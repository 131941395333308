export const emojis = [
    `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="emoji-bg" d="M28 0H8C3.58172 0 0 3.58172 0 8V28C0 32.4183 3.58172 36 8 36H28C32.4183 36 36 32.4183 36 28V8C36 3.58172 32.4183 0 28 0Z" fill="#4B4B4B"/>
<path d="M8.03514 11.6545C7.96619 11.2236 8.00067 10.7581 8.12134 10.3272C8.25924 9.89622 8.48334 9.4825 8.79363 9.12049C9.10392 8.77573 9.5004 8.48267 9.93136 8.29305C10.3623 8.10343 10.845 8 11.3277 8C11.8103 8 12.2758 8.10343 12.724 8.29305C13.1549 8.48267 13.5514 8.77573 13.8617 9.12049C14.172 9.46526 14.4133 9.87898 14.534 10.3272C14.6719 10.7581 14.7064 11.2236 14.6202 11.6545C14.5685 11.9131 14.3271 12.0855 14.0686 12.051C13.8789 12.0165 13.7238 11.8786 13.6893 11.689V11.6545C13.5342 11.0339 13.2239 10.534 12.8102 10.1893C12.3792 9.8445 11.8793 9.65488 11.3449 9.65488C10.8278 9.65488 10.3106 9.8445 9.87965 10.1893C9.44869 10.534 9.1384 11.0339 9.00049 11.6545V11.689C8.93154 11.9476 8.67296 12.1027 8.41439 12.051C8.20753 11.9821 8.06962 11.8269 8.03514 11.6545Z" fill="#22252E"/>
<path d="M21.9009 11.6545C21.8319 11.2236 21.8664 10.7581 21.9871 10.3272C22.125 9.89622 22.3491 9.4825 22.6594 9.12049C22.9696 8.77573 23.3661 8.48267 23.7971 8.29305C24.228 8.10343 24.7107 8 25.1934 8C25.6761 8 26.1415 8.10343 26.5897 8.29305C27.0207 8.48267 27.4171 8.77573 27.7274 9.12049C28.0377 9.46526 28.2791 9.87898 28.3997 10.3272C28.5376 10.7581 28.5721 11.2236 28.4859 11.6545C28.4342 11.9131 28.1929 12.0855 27.9343 12.051C27.7447 12.0165 27.5895 11.8786 27.555 11.689V11.6545C27.3999 11.0339 27.0896 10.534 26.6759 10.1893C26.2449 9.8445 25.745 9.65488 25.2106 9.65488C24.6935 9.65488 24.1763 9.8445 23.7454 10.1893C23.3144 10.534 23.0041 11.0339 22.8662 11.6545V11.689C22.7973 11.9476 22.5387 12.1027 22.2801 12.051C22.0733 11.9821 21.9353 11.8269 21.9009 11.6545Z" fill="#22252E"/>
<path d="M11.6777 24.4622C13.1208 25.9738 15.1749 27.091 17.7959 27.2206C20.5024 27.3543 22.767 26.3044 24.4092 24.5543C19.2245 22.0082 14.1524 23.4363 11.6777 24.4622Z" fill="#22252E"/>
<path d="M9.20434 18.4609C9.13295 20.3701 9.98448 22.688 11.678 24.462C14.1526 23.4361 19.2248 22.008 24.4095 24.5538C25.8908 22.9754 26.866 20.8275 27.2002 18.4609C22.1697 20.2933 14.9842 19.8461 9.20434 18.4609Z" fill="#22252E"/>
</svg>

`, `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="emoji-bg" d="M28 0H8C3.58172 0 0 3.58172 0 8V28C0 32.4183 3.58172 36 8 36H28C32.4183 36 36 32.4183 36 28V8C36 3.58172 32.4183 0 28 0Z" fill="#4B4B4B"/>
<path d="M9 26.4536C12.2276 17.7724 23.5799 17.8837 26.9188 26.4536C22.2443 19.3306 13.6745 19.2193 9 26.4536Z" fill="#22252E"/>
<ellipse cx="11.7087" cy="12.7998" rx="1.67988" ry="2.79981" fill="#22252E"/>
<ellipse cx="24.028" cy="12.7998" rx="1.67988" ry="2.79981" fill="#22252E"/>
</svg>

`, `<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="emoji-bg" d="M28.7969 0H8.79688C4.3786 0 0.796875 3.58172 0.796875 8V28C0.796875 32.4183 4.3786 36 8.79687 36H28.7969C33.2152 36 36.7969 32.4183 36.7969 28V8C36.7969 3.58172 33.2152 0 28.7969 0Z" fill="#4B4B4B"/>
<path d="M12 24.6484H24.96H12Z" fill="#22252E"/>
<path d="M12 24.6484H24.96" stroke="#22252E" stroke-miterlimit="10" stroke-linecap="round"/>
<ellipse cx="12.6799" cy="13.7998" rx="1.67988" ry="2.79981" fill="#22252E"/>
<ellipse cx="24.9992" cy="13.7998" rx="1.67988" ry="2.79981" fill="#22252E"/>
</svg>

`, `<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="emoji-bg" d="M28.7969 0H8.79688C4.3786 0 0.796875 3.58172 0.796875 8V28C0.796875 32.4183 4.3786 36 8.79687 36H28.7969C33.2152 36 36.7969 32.4183 36.7969 28V8C36.7969 3.58172 33.2152 0 28.7969 0Z" fill="#4B4B4B"/>
<path d="M27.9189 19.9918C24.6913 28.6729 13.3391 28.5616 10.0002 19.9918C14.6746 27.1147 23.2445 27.226 27.9189 19.9918Z" fill="#22252E"/>
<ellipse cx="12.7087" cy="12.7998" rx="1.67988" ry="2.79981" fill="#22252E"/>
<ellipse cx="25.028" cy="12.7998" rx="1.67988" ry="2.79981" fill="#22252E"/>
</svg>
`, `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="emoji-bg" d="M28 0H8C3.58172 0 0 3.58172 0 8V28C0 32.4183 3.58172 36 8 36H28C32.4183 36 36 32.4183 36 28V8C36 3.58172 32.4183 0 28 0Z" fill="#4B4B4B"/>
<path d="M8.03514 11.6545C7.96619 11.2236 8.00067 10.7581 8.12134 10.3272C8.25924 9.89622 8.48334 9.4825 8.79363 9.12049C9.10392 8.77573 9.5004 8.48267 9.93136 8.29305C10.3623 8.10343 10.845 8 11.3277 8C11.8103 8 12.2758 8.10343 12.724 8.29305C13.1549 8.48267 13.5514 8.77573 13.8617 9.12049C14.172 9.46526 14.4133 9.87898 14.534 10.3272C14.6719 10.7581 14.7064 11.2236 14.6202 11.6545C14.5685 11.9131 14.3271 12.0855 14.0686 12.051C13.8789 12.0165 13.7238 11.8786 13.6893 11.689V11.6545C13.5342 11.0339 13.2239 10.534 12.8102 10.1893C12.3792 9.8445 11.8793 9.65488 11.3449 9.65488C10.8278 9.65488 10.3106 9.8445 9.87965 10.1893C9.44869 10.534 9.1384 11.0339 9.00049 11.6545V11.689C8.93154 11.9476 8.67296 12.1027 8.41439 12.051C8.20753 11.9821 8.06962 11.8269 8.03514 11.6545Z" fill="#22252E"/>
<path d="M21.9009 11.6545C21.8319 11.2236 21.8664 10.7581 21.9871 10.3272C22.125 9.89622 22.3491 9.4825 22.6594 9.12049C22.9696 8.77573 23.3661 8.48267 23.7971 8.29305C24.228 8.10343 24.7107 8 25.1934 8C25.6761 8 26.1415 8.10343 26.5897 8.29305C27.0207 8.48267 27.4171 8.77573 27.7274 9.12049C28.0377 9.46526 28.2791 9.87898 28.3997 10.3272C28.5376 10.7581 28.5721 11.2236 28.4859 11.6545C28.4342 11.9131 28.1929 12.0855 27.9343 12.051C27.7447 12.0165 27.5895 11.8786 27.555 11.689V11.6545C27.3999 11.0339 27.0896 10.534 26.6759 10.1893C26.2449 9.8445 25.745 9.65488 25.2106 9.65488C24.6935 9.65488 24.1763 9.8445 23.7454 10.1893C23.3144 10.534 23.0041 11.0339 22.8662 11.6545V11.689C22.7973 11.9476 22.5387 12.1027 22.2801 12.051C22.0733 11.9821 21.9353 11.8269 21.9009 11.6545Z" fill="#22252E"/>
<path d="M11.6777 24.4622C13.1208 25.9738 15.1749 27.091 17.7959 27.2206C20.5024 27.3543 22.767 26.3044 24.4092 24.5543C19.2245 22.0082 14.1524 23.4363 11.6777 24.4622Z" fill="#22252E"/>
<path d="M9.20434 18.4609C9.13295 20.3701 9.98448 22.688 11.678 24.462C14.1526 23.4361 19.2248 22.008 24.4095 24.5538C25.8908 22.9754 26.866 20.8275 27.2002 18.4609C22.1697 20.2933 14.9842 19.8461 9.20434 18.4609Z" fill="#22252E"/>
</svg>

`
]